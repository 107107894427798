import React, { useState } from "react"
import "../styles/accordion.scss"

const Accordion = () => {
  const [questions, setQuestions] = useState([
    {
      id: 1,
      pitanje: "Sa koliko godina je moguce početi obuku vozača?",
      odgovor:
        "Obuku za vozača je moguće početi sa 16 godina. Sa punih 17 godina je moguće polagati vozački ispit.",
      open: false,
    },
    {
      id: 2,
      pitanje: "Koliki je minimalni broj časova vožnje pre polaganja ispita?",
      odgovor:
        "Minimalni broj časova je 40. Trajanje jednog časa je 45 minuta.",
      open: false,
    },
    {
      id: 3,
      pitanje: "Ko čini ispitnu komisiju?",
      odgovor:
        "Ispitnu komisiju čine dva člana: licencirani ispitivač iz auto škole i ispitivač iz Ministarstva unutrašnjih poslova.",
      open: false,
    },
    {
      id: 4,
      pitanje: "Da li je obavezno prisustvovanje teorijskim predavanjima?",
      odgovor:
        "Da, ukoliko ste nekim slučajem sprečeni da dođete na teorijsko predavanje, taj čas u dogovoru sa predavačem morate nadoknaditi do kraja teorijske obuke. Teorijska predavanja su jako korisna i olakšaće Vam polaganje teorijskog ispita kao i sam proces praktične obuke. O važnosti poznavanja gradiva kasnije kada budete samostalni vozač ne treba ni govoriti.",
      open: false,
    },
    {
      id: 5,
      pitanje: "Za koji vremenski period se može završiti vozački ispit?",
      odgovor:
        "Vozački ispiti se može uspešno završiti i za 75 dana, sve zavisi od Vas.",
      open: false,
    },
    {
      id: 6,
      pitanje: "Šta je potrebno od dokumenata za upis u Auto školu?",
      odgovor: "Dovoljna je samo lična karta.",
      open: false,
    },
    {
      id: 7,
      pitanje: "Koja je procedura uplata obuke i polaganja vozačkog ispita?",
      odgovor:
        "Uplata može biti i na rate, prva rata prilikom upisa, može i čekovima građana kao i administrativnom zabranom.",
      open: false,
    },
    {
      id: 8,
      pitanje: "Koliko važi lekarsko uverenje?",
      odgovor:
        "Za obuku i polaganje teorijskog i/ili praktičnog ispita lekarsko uverenje ne sme biti starije od 12 meseci, a za izdavanje vozačke dozvole uverenje ne sme biti starije od 6 meseci.",
      open: false,
    },
    {
      id: 9,
      pitanje: "Kolika je cena prakticne obuke?",
      odgovor: "Cena praktične obuke za B kategoriju je 40.000 dinara.",
      open: false,
    },
    {
      id: 10,
      pitanje: "Kolika je cena teorijske obuke?",
      odgovor: "Cena teorijske obuke za B kategoriju je 12.000 dinara.",
      open: false,
    },
  ])

  const handleClick = id => {
    const newState = questions.map(el =>
      el.id === id ? { ...el, open: !el.open } : { ...el, open: false }
    )
    setQuestions(newState)
  }

  return (
    <div className="container">
      <ul className="collapsible popout" data-collapsible="accordion">
        {questions.map(question => (
          <li className={question.open ? "active" : ""} key={question.id}>
            <div
              className="collapsible-header"
              onClick={() => handleClick(question.id)}
              onKeyDown={() => handleClick(question.id)}
              role="button"
              tabIndex={question.id}
            >
              {question.pitanje}
            </div>
            <div
              className={
                question.open
                  ? "collapsible-body-active collapsible-body"
                  : "collapsible-body"
              }
            >
              <p>{question.odgovor}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Accordion
