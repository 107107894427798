/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Icon from "../components/icon"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header siteTitle="Auto Škola Vedo Driver" />
      <div>
        <main>{children}</main>
        <footer className="footer-outer">
          <div className="footer-inner">
            <h2 className="footer-heading">{"Auto Škola Vedo Driver"}</h2>
            <div className="footer-links">
              <Link to="#advantages" className="footer-link">
                O nama
              </Link>
              <Link to="#features" className="footer-link">
                Instruktori
              </Link>
              <Link to="#faq" className="footer-link">
                FAQ
              </Link>
              <Link to="#map" className="footer-link ">
                Kontakt
              </Link>
              <Link to="#map" className="footer-link ">
                Prijavi se
              </Link>
            </div>
            <div className="footer-media">
              <a
                href="https://www.instagram.com/vedodriver"
                target="_blank"
                rel="noreferrer"
              >
                <Icon iconName="instagram" className="svg-icon" />
              </a>
              <a
                href="https://www.facebook.com/vedodrivernp/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon iconName="facebook" className="svg-icon" />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
