import React from "react"
import Layout from "../components/layout"
import "../styles/Index.scss"
import Card from "../components/card"
import Accordion from "../components/accordion"
import Vedo from "../images/veksi.jpeg"
import Albin from "../images/AlbinSlika.jpeg"
import Faruk from "..//images/faruk1.jpeg"
import Beca from "..//images/becaSlika.jpeg"
import Boba from "..//images/BobaSlika.jpeg"
import favicon from "../images/rsz_driver_logo.jpg"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

export const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          keywords
          url
        }
      }
    }
  `)
  return (
    <Layout>
      <Helmet>
        <title>Auto Škola Vedo Driver</title>
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content={query.site.siteMetadata.description}
        />

        <meta name="keywords" content={query.site.siteMetadata.keywords} />
        <meta property="og:title" content={query.site.siteMetadata.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={query.site.siteMetadata.description}
        />
        <meta property="og:locale" content="sr-Latn-RS" />
        <meta property="og:url" content={query.site.siteMetadata.url} />
        <link rel="canonical" href={query.site.siteMetadata.url} />
      </Helmet>
      <section id="landing">
        <div className="landing-content">
          <div className="landing-co">
            <p className="landing-header">Auto Škola Vedo-Driver</p>
            <p className="landing-header2">
              Učinimo svaki kilometar vožnje bezbednim i zabavnim!
            </p>
            <Link to="#map">
              <button className="landing-button">Započni obuku</button>
            </Link>
          </div>
        </div>
      </section>

      <section id="advantages">
        <div className="container">
          <h2 className="advantages-header">Zašto naša auto škola?</h2>
          <div className="advantages-cards">
            <Card
              iconName="smart"
              header="Kvalitet obuke"
              text="Naša škola će Vam pružiti sve mogućnosti i komfor da učite vožnju u bezbednim automobilima,
             uz konstantnu pažnju naših lincenciranih instruktora. "
            />

            <Card
              iconName="credit"
              header="Mogućnost odloženog plaćanja"
              text="S obzirom na platežnu moć građana dajemo različite mogućnosti plaćanja u više mesečnih rata.    
          "
            />
            <Card
              iconName="heart"
              header="Besplatno lekarsko uverenje"
              text="Naša auto škola se trudi da što više izlazi u susret studentima i učenicima, zato njima poklanjamo besplatno lekarsko uverenje!"
            />
          </div>
        </div>
      </section>
      <section id="features">
        <div className="features-curved" />
        <div className="features-content">
          <div className="features-container">
            <div className="features-image">
              <img src={Vedo} alt="Vedo" />
            </div>
            <div className="features-inst1">
              <h1>Vedad Prušević</h1>
              <p>
                Diplomirani inžinjer saobraćaja i osnivač auto škole
                Vedo-Driver. Licencirani predavač, ispitivač i instrukror
                budućih vozača. Takodje poseduje sertifikat predavača za
                profesionalne vozače, kao i sertifikat predavača za vozače
                kojima je oduzeta vozačka dozvola.{" "}
              </p>
            </div>
          </div>
          <div className="features-container">
            <div className="features-image">
              <img src={Faruk} alt="Faruk" />
            </div>

            <div className="features-inst2">
              <h1>Faruk Radončić</h1>
              <p>
                Naša obuka se fokusira na sve aspekte vožnje, uključujući
                pravila saobraćaja, sigurnosne protokole i taktike defanzivne
                vožnje. Faruk koristi napredne metode učenja i prilagođava svoj
                pristup svakom polazniku, kako bi osigurao da svako dobije
                individualnu pažnju i podršku koja im je potrebna za uspeh.{" "}
              </p>
            </div>
          </div>

          <div className="features-container">
            <div className="features-image">
              <img src={Beca} alt="Beca" />
            </div>
            <div className="features-inst1">
              <h1>Mirsada Bogućanin (Beca)</h1>
              <p>
                Nastojimo da učinimo proces učenja vožnje zabavnim i prijatnim
                iskustvom. Uz Mirsadinu strpljivost i motivaciju, brzo ćete se
                osećati sigurno i samouvereno za upravljačem. Naš cilj je ne
                samo da vam pomognemo da položite vozački ispit, već i da
                steknete veštine i znanja koja će vam trajno koristiti na putu.{" "}
              </p>
            </div>
          </div>
          <div className="features-container">
            <div className="features-image">
              <img src={Albin} alt="Albin" />
            </div>
            <div className="features-inst2">
              <h1>Albin Đerlek</h1>
              <p>
                Kao licencirani instruktor vožnje B kategorije, Albin je
                posvećen pružanju vrhunske obuke koja će vam omogućiti da
                postanete samouvereni vozači. Sa bogatim iskustvom i strašću
                prema vožnji, Albin je stekao mnogo poznanika i prijatelja koji
                ga prepoznaju kao instruktora koji ostavlja neizbrisiv trag u
                sećanju svojim kandidatima.{" "}
              </p>
            </div>
          </div>
          <div className="features-container">
            <div className="features-image">
              <img src={Boba} alt="Boba" />
            </div>
            <div className="features-inst1">
              <h1>Božidarka Miletic (Boba)</h1>
              <p>
                Božidarka je instruktorka vožnje i administratorka auto škole.
                Njena ljubaznost, strpljenje i temeljito znanje saobracajnih
                pravila čine je izvrsnim mentorom za sve polaznike auto škole.
                Njen organizovan pristup administrativnim zadacima osigurava
                besprijekorno funkcioniranje autoskole. Uvek sa osmehom na licu,
                Božidarka stvara pozitivno okruženje u kojem se učenici osjećaju
                dobrodošlo i sigurno dok usavršavaju svoje vozačke veštine.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="faq">
        <h2 className="advantages-header faq-header">
          Često postavljana pitanja?
        </h2>
        <Accordion />
      </section>

      <section id="map">
        <h2 className="advantages-header">Kontakt</h2>
        <div className="map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2911.535464770918!2d20.512541286512647!3d43.13528389924851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4756293354e7019f%3A0xc3100ed61faf4361!2sAuto%20%C5%A0kola%20Vedo-Driver!5e0!3m2!1sen!2srs!4v1623498355149!5m2!1sen!2srs"
            height="500"
            style={{ border: 0, width: "100%" }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        <form
          className="map-form"
          name="Contact Form"
          method="POST"
          data-netlify="true"
          action="/thank-you"
        >
          <input type="hidden" name="form-name" value="Contact Form" />

          <h2 className="advantages-header">Prijavi se za obuku</h2>

          <div className="input-container">
            <input
              id="name"
              name="name"
              className="input"
              type="text"
              pattern=".+"
              required
            />
            <label className="label" htmlFor="name">
              Ime i prezime
            </label>
          </div>

          <div className="input-container">
            <input
              id="email"
              name="email"
              className="input"
              type="email"
              pattern=".+"
              required
            />
            <label className="label" htmlFor="email">
              Email
            </label>
          </div>
          <div className="input-container">
            <input
              id="phoneNo"
              className="input"
              type="text"
              pattern=".+"
              name="phoneNo"
              required
            />
            <label className="label" htmlFor="phoneNo">
              Broj telefona
            </label>
          </div>
          <button type="submit" className="landing-button">
            Pošalji prijavu
          </button>
        </form>
      </section>
    </Layout>
  )
}

export default IndexPage
