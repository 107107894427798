import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Burger from "@animated-burgers/burger-squeeze"
import "@animated-burgers/burger-squeeze/dist/styles.css"
import "../styles/Header.scss"
import Icon from "./icon"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <header>
      <div className="nav">
        <div>
          <Link to="/" className="nav-header">
            VedoDriver
          </Link>
        </div>

        <div
          className={
            isOpen ? "nav-link-group nav-link__active" : "nav-link-group"
          }
        >
          <Link to="#advantages" className="nav-link">
            O nama
          </Link>
          <Link to="#features" className="nav-link">
            Instruktori
          </Link>
          <Link to="#faq" className="nav-link">
            FAQ
          </Link>
          <Link to="#map" className="nav-link">
            Kontakt
          </Link>
          <Link to="#map" className="nav-link">
            Prijavi se
          </Link>
        </div>

        <div className={isOpen ? "nav-icons nav-icons__active" : "nav-icons"}>
          <a
            href="https://www.instagram.com/vedodriver"
            target="_blank"
            rel="noreferrer"
          >
            <Icon iconName="instagram" className="svg-icon" />
          </a>
          <a
            href="https://www.facebook.com/vedodrivernp/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon iconName="facebook" className="svg-icon" />
          </a>
        </div>

        <Burger
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          className="nav-burger"
        />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
