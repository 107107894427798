import React from "react"
import "../styles/Garbage.scss"
import iconPath from "./iconPath"

const Icon = ({ iconName, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path d={iconPath[iconName]} />
    </svg>
  )
}

export default Icon
