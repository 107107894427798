import React from "react"
import "../styles/Index.scss"
import Icon from "./icon"

const Card = ({ iconName, header, text }) => {
  return (
    <div className="advantages-card">
      <Icon iconName={iconName} className="advantages-icon" />
      <div>
        <h5>{header}</h5>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default Card
